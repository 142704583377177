//import { createRouter, createWebHistory } from 'vue-router'

import Vue from 'vue';
import Router from 'vue-router';
import routes from './routers';
import store from '@/store';
import Setting from '@/setting';
import { removeCookies, getCookies, setTitle } from '@/libs/util';
import { includeArray } from '@/libs/auth';


Vue.use(Router);
// const router = createRouter({
//   routes,
//   history: createWebHistory(process.env.BASE_URL),
//   scrollBehavior() {
//     return { top: 0 }
//   }
// })




const router = new Router({
  routes,
  mode: Setting.routerMode,
});




/**
 * 路由拦截
 * 权限验证
 */

router.beforeEach(async (to, from, next) => {
  // PrevLoading.start();
  
  //console.log(getCookies('token'));
  //return 1;
  //keepAliveSplice(to);
  // if (Setting.showProgressBar) iView.LoadingBar.start()
  // 判断是否需要登录才可以进入
  if (to.matched.some((_) => _.meta.auth)) {
    // 这里依据 token 判断是否登录，可视情况修改
    const token = getCookies('token');
    if (token && token !== 'undefined') {
      const access = store.state.login.uniqueAuth;
      const isPermission = to.meta.auth ? includeArray(to.meta.auth, access) : true; //  判断是否有权限  TODO
	  next();
	  if(!isPermission){
		next({
		  name: '403',
		});
	  }
      if (access.length) {
        next();
      } else {
        if (access.length == 0) {
          next({
            name: 'login',
            query: {
              redirect: to.fullPath,
            },
          });
          localStorage.clear();
          removeCookies('token');
          removeCookies('expires_time');
          removeCookies('uuid');
        } else {
          next({
            name: '403',
          });
        }
      }
      // next();
    } else {
      // 没有登录的时候跳转到登录界面
      // 携带上登陆成功之后需要跳转的页面完整路径
      next({
        name: 'login',
        query: {
          redirect: to.fullPath,
        },
      });
      localStorage.clear();
      removeCookies('token');
      removeCookies('expires_time');
      removeCookies('uuid');
    }
  } else {
    // 不需要身份校验 直接通过
    next();
  }
});

export default router
