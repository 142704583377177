<template>
  <div>
    <el-dialog :visible.sync="modals" :width="isMobile ? '100%' : '400px'" :title="titleFrom" @closed="clearFrom">
      <el-form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-width="100px" @submit.native.prevent v-loading="spink">
        <el-row :gutter="24">
          
		  
		  <el-col>
			  <el-form-item label="原密码：" prop="pwd">
			    <el-input type="password" v-model="formValidate.pwd" class="input"></el-input>
			  </el-form-item>
		  </el-col>
		  <el-col>
			  <el-form-item label="新密码：" prop="new_pwd">
			    <el-input type="password" v-model="formValidate.new_pwd" class="input"></el-input>
			  </el-form-item>
		  </el-col>
		  <el-col>
			  <el-form-item label="确认新密码：" prop="conf_pwd">
			    <el-input type="password" v-model="formValidate.conf_pwd" class="input"></el-input>
			  </el-form-item>
		  </el-col>
		  
		  
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleReset">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('formValidate')" :disabled="loading">提 交</el-button>
      </span>
    </el-dialog>
    
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { updateLoginPassword } from '@/api/auth/admin';
export default {
  name: 'updatePasswordForm',
  
  data() {
    return {

      grid: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24,
      },
	  spink:false,
	  loading:false,
      modals: false,
	  titleFrom:'修改资料',
	  formValidate:{
		pwd:'',
		new_pwd:'',
		conf_pwd:''
	  },
	  
      ruleValidate: {
        pwd: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
		new_pwd: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
		conf_pwd: [{ required: true, message: '请再次输入新密码', trigger: 'blur' }],
      },
    };
  },
  watch: {
    
  },
  computed: {
    ...mapState('media', ['isMobile']),
  },
  components: {  },
  methods: {
    
    // 提交
    handleSubmit(name) {
      
      this.loading = true;
	  
	  this.$refs[name].validate((valid) => {
	    if (valid) {
	      updateLoginPassword(this.formValidate)
	        .then(async (res) => {
	          this.$message.success(res.msg);
	          this.modals = false;
	          this.$emit('getList');
	      	  this.loading = false;
			  this.clearFrom();
	        })
	        .catch((res) => {
	          this.loading = false;
	          this.$message.error(res.msg);
	        });
	    } else {
	  	  this.loading = false;
	      return false;
	    }
	  });
	  
      
    },
	
    handleReset() {
      this.modals = false;
      this.clearFrom();
    },
    
    // 清除表单数据
    clearFrom() {
      this.formValidate = Object.assign({}, this.$options.data().formValidate);
    },
	
  },
  created() {
  },
};
</script>

<style scoped >
::v-deep .avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 50%;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
::v-deep .avatar-uploader .el-upload:hover {
	border-color: #409EFF;
}
::v-deep .avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 80px;
	height: 80px;
	line-height: 80px;
	text-align: center;
}
::v-deep .avatar {
	width: 80px;
	height: 80px;
	display: block;
}
</style>
