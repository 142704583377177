

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'auth_';

export default {
  path: routePre + '/auth',
  name: 'auth',
  header: 'auth',
  redirect: {
    name: `${pre}index`,
  },
  component: LayoutMain,
  children: [
	
	
	
	{
	  path: 'admin',
	  name: `${pre}admin`,
	  meta: {
	    auth: ['auth-admin'],
	    title: '管理员',
	  },
	  component: () => import('@/pages/auth/admin'),
	},
	{
	  path: 'wechat',
	  name: `${pre}wechat`,
	  meta: {
	    auth: ['auth-wechat'],
	    title: '接量账户',
	  },
	  component: () => import('@/pages/auth/wechat'),
	},
	{
	  path: 'team',
	  name: `${pre}team`,
	  meta: {
	    auth: ['auth-team'],
	    title: '战队管理',
	  },
	  component: () => import('@/pages/auth/team'),
	},
	{
	  path: 'role',
	  name: `${pre}role`,
	  meta: {
	    auth: ['auth-role'],
	    title: '职位管理',
	  },
	  component: () => import('@/pages/auth/role'),
	},
	{
	  path: 'channelcode',
	  name: `${pre}channelcode`,
	  meta: {
	    auth: ['auth-channelcode'],
	    title: '渠道码',
	  },
	  component: () => import('@/pages/auth/channelcode'),
	}
	
	
  ],
};
