<template>
  <el-aside hide-trigger class="siderMenu layout-aside" :class="setCollapseWidth">
      <el-menu 
	  :default-active="activePath" 
	  ref="menu" 
	  width="auto"
	  background-color="transparent"
	  :class="setColumnsAsideStyle"
	  :default-openeds="openNames"
	  >
          <template v-for="(item,index) in menuList">
			  <el-submenu :index="item.path" v-if="item.children && item.children.length > 0" :key="item.path">
				  <template slot="title">
					  <!-- <Icon type="ios-navigate"></Icon> -->
					  {{item.title}}
				  </template>
				  <el-menu-item :index="children.path" v-for="children in item.children" @click="selectMenu(children.path)">{{children.title}}</el-menu-item>
			  </el-submenu>
			  <el-menu-item :index="item.path" v-else @click="selectMenu(item.path)" :key="item.path">
				  <!-- <Icon type="ios-paper" /> -->
				  {{item.title}}
			  </el-menu-item>
		  </template>
      </el-menu>
  </el-aside>
</template>

<script>
import {  getSiderSubmenu } from '@/libs/system';
import { mapState } from 'vuex';
export default {
  name: 'layoutAside',
  components: {},
  props: {
    menuList: Array,
	openNames:Array,
	activePath:String,
  },
  data() {
    return {
      clientWidth: '',
      catName: '',
    };
  },
  computed: {
	// 设置分栏高亮风格
	setColumnsAsideStyle() {
	  return this.$store.state.themeConfig.themeConfig.columnsAsideStyle;
	},  
    
    setCollapseWidth() {
      let { layout, isCollapse } = this.$store.state.themeConfig.themeConfig;
      let asideBrColor = '';
      layout === 'classic' || layout === 'columns' ? (asideBrColor = 'layout-el-aside-br-color') : '';
	
        // 其它布局给 64px
        if (isCollapse) {
          return ['layout-aside-width1', asideBrColor];
        } else {
          return ['layout-aside-width-default', asideBrColor];
        }
    },
    
  },
  created() {
	  
    
  },
  
  methods: {
	selectMenu(path)
	{
		this.$router.push(path)
	},
    
  },
  

};
</script>
<style lang="scss" scoped>

.el-menu{
	border-right:0;
}

</style>
