

import { tableDelApi } from '@/api/common';
export function modalSure(delfromData) {
  return new Promise((resolve, reject) => {
    let content = `<p>${delfromData.title}</p>`;
    if (!delfromData.info) {
      delfromData.info = '';
    }
    const h = this.$createElement
    this.$msgbox({
      title: '提示',
      message: h('p', null, [
        h('div', null, `${delfromData.title}`),
        h('div', null, `${delfromData.info}`)
      ]),
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '确定',
      iconClass: 'el-icon-warning',
      confirmButtonClass: 'btn-custom-cancel',
	  center: true
    }).then(() => {
      if (delfromData.success) {
        delfromData.success
          .then(async (res) => {
            resolve(res);
          })
          .catch((res) => {
            reject(res);
          });
      } else {
        tableDelApi(delfromData)
          .then(async (res) => {
            resolve(res);
          })
          .catch((res) => {
            reject(res);
          });
      }
    }).catch(() => {

    })
  });
}
