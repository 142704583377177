<template>
  <div class="layout-footer mt15">
    <div class="layout-footer-warp">
      <div class="ivu-global-footer i-copyright">
        
        <div class="ivu-global-footer-copyright" >
          Copyright © 2023-2024 Gtui {{$store.state.login.version}}
          <!-- <a href="https://www.crmeb.com" target="_blank">0.02</a> -->
        </div>
		<div class="ivu-global-footer-links acea-row row-center-wrapper" >
			
		  <!-- <a :href="item.href" target="_blank" v-for="(item, index) in links" :key="index">{{ item.title }}</a> -->
		  
		  
		  <div class="item">
			<el-popover
			  placement="top"
			  trigger="hover">
				<div>
					<img src="@/assets/images/weixin.png" width="300"/>
				</div>
				<div slot="reference" class="acea-row row-middle">
				  <div>在线客服</div><div class="iconfont icon-weixin reduceColor"></div>
				</div>
			</el-popover>
		  </div>
		  
		  <!-- <a :href="'http://www.baidu.com'" target="_blank" >推广者计划</a> -->
		</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {  },
  name: 'layoutFooter',
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.layout-footer {
  width: 100%;
  // display: flex;
  // position: absolute;
  // bottom: 0;
  &-warp {
    margin: auto;
    color: var(--prev-color-text-secondary);
    text-align: center;
  }
}
.ivu-global-footer {
  /* margin: 48px 0 24px 0; */
  /* padding: 0 16px; */
  margin: 5px 0px;
  text-align: center;
  box-sizing: border-box;
}
.i-copyright {
  flex: 0 0 auto;
}
.ivu-global-footer-links {
  margin-top: 7px;
}

.ivu-global-footer-links .item:not(:last-child) {
  margin-right: 40px;
}
.ivu-global-footer-links .item {
  font-size: 14px;
  color: #808695;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  .icon-weixin{
	  font-size:20px;
	  margin-left: 4px;
  }
}

.ivu-global-footer-copyright {
  color: #808695;
  font-size: 14px;
}
.ivu-global-footer-copyright a {
  color: #808695;
}
</style>
