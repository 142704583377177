export default {
  namespaced: true,
  state: {
    configs: null,
	
  },
  mutations: {
    configs(state, configs) {
      state.configs = configs;
    },
	
  },
  actions: {
    
  },
};
