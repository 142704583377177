import Vue from 'vue'
import App from './App.vue'

import settings from '@/setting';
import router from './router'
import store from './store'

import modalForm from '@/utils/modalForm';
import { modalSure } from '@/utils/public';

import formCreate from '@form-create/element-ui';
Vue.use(formCreate);


import importDirective from '@/directive';
//import { directive as clickOutside } from 'v-click-outside-x';

import '@/theme/index.scss';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small' });
import '@/styles/index.scss'
//import '@/styles/theme.scss'

import pagesHeader from '@/components/pagesHeader';
Vue.component('pagesHeader', pagesHeader);


import Pagination from '@/components/Pagination';
Vue.component('Pagination', Pagination);

Vue.prototype.$routeProStr = settings.routePre;
Vue.prototype.$modalForm = modalForm;
Vue.prototype.$modalSure = modalSure;

Vue.prototype.bus = new Vue();

/**
 * 注册指令
 */
importDirective(Vue);
//Vue.directive('clickOutside', clickOutside);


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
