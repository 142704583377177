import setting from '@/setting';
let routePre = setting.routePre;

export default [
  // 登录
  {
    path: routePre + '/login',
    name: 'login',
    meta: {
      title: '登录',
      hideInMenu: true,
    },
    component: () => import('@/pages/account/login/index'),
  }
];
