<template>
  <div>
    <el-dialog :visible.sync="modals" :width="isMobile ? '100%' : '540px'" :title="titleFrom" @closed="clearFrom">
      <el-form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-width="80px" @submit.native.prevent v-loading="spink">
        <el-row :gutter="24">
          
		  
		  <el-col v-bind="grid">
		    <el-form-item label="头像：" prop="head_pic">
				<upload-image v-model="formValidate.head_pic"></upload-image>
		      </el-upload>
		    </el-form-item>
		  </el-col>
		  
		  
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleReset">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('formValidate')" :disabled="loading">提 交</el-button>
      </span>
    </el-dialog>
    
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { updateLoginInfo } from '@/api/auth/admin';
import uploadImage from '@/components/zuoy/uploadImage';

export default {
  name: 'updateAdminForm',
  
  data() {
    return {
      grid: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24,
      },
	  spink:false,
	  loading:false,
      modals: false,
	  titleFrom:'修改资料',
	  formValidate:{
		
		head_pic:'',
	  },
	  
      ruleValidate: {
        head_pic: [{ required: true, message: '请上传头像', trigger: 'change' }],
      },
    };
  },
  watch: {
    
  },
  computed: {
    ...mapState('media', ['isMobile']),
  },
  components: { uploadImage },
  methods: {
    init(user)
	{
		this.formValidate.head_pic = user.head_pic
	},
    // 提交
    handleSubmit(name) {
      
      this.loading = true;
	  
	  this.$refs[name].validate((valid) => {
	    if (valid) {
	      updateLoginInfo(this.formValidate)
	        .then(async (res) => {
	          this.$message.success(res.msg);
	          this.modals = false;
	          this.$emit('getList');
	      	  this.loading = false;
			  this.clearFrom();
	        })
	        .catch((res) => {
	          this.loading = false;
	          this.$message.error(res.msg);
	        });
	    } else {
	  	  this.loading = false;
	      return false;
	    }
	  });
	  
      
    },
	
    handleReset() {
      this.modals = false;
      this.clearFrom();
    },
    
    // 清除表单数据
    clearFrom() {
      this.formValidate = Object.assign({}, this.$options.data().formValidate);
    },
	
	
	
  },
  created() {
  },
};
</script>

<style scoped >

</style>
